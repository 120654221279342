@use '@angular/material' as mat;

@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: rgba(black, 0.87);


//https://v13.material.angular.io/guide/theming
//https://material.io/design/color/the-color-system.html#tools-for-picking-colors
//https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=ff1745&secondary.color=d0f3c6
//https://github.com/angular/components/blob/main/src/material/core/theming/_palette.scss
$primary-palette: (
  50: #F1FDEB ,
  100: #DBF9CD ,
  200: #C2F5AD ,
  300: #A8F18A ,
  400: #91EC6C ,
  500: #7AE74F ,
  600: #6AD547 ,
  700: #52C03C ,
  800: #3AAB31 ,
  900: #008721 ,
//   A100: #ff8a80,
//   A200: #ff5252,
//   A400: #ff1744,
//   A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$accent-palette: (
  50: #F6E6FB ,
  100: #E7C1F6 ,
  200: #D898F1 ,
  300: #C86CEC ,
  400: #BB48E7 ,
  500: #AC27DD ,
  600: #9B26D7 ,
  700: #8423CE ,
  800: #6F21C7 ,
  900: #441EB9 ,
//   A100: #ff8a80,
//   A200: #ff5252,
//   A400: #ff1744,
//   A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$my-primary: mat.define-palette($primary-palette, 400); //pallette, default, lighter, darker, text
$my-accent: mat.define-palette($accent-palette, 100);

$barlow-typography: mat.define-typography-config(
    $font-family: 'Barlow',
  );

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: $barlow-typography
));

@include mat.all-component-themes($my-theme);

/* 
    Custom not related to theme
    You can add global styles to this file, and also import other style files 
*/

html, body { height: 100%; }
body { 
    margin: 0;
    font-family: 'Barlow', "Helvetica Neue", sans-serif;
}

@media print {
  app-root .outlet {
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }

  .for-print, .for-print * {
      visibility: visible;
  }

  .not-for-print, .not-for-print * {
    visibility: hidden;
}
  
  body * {
      visibility: hidden;
  }

}
